import React from "react";
import "./Loader.css";

const Loader = props => {
    return (
        <div className="loader">
            <div>
                <h2>Loading ...</h2>
            </div>
        </div>
    );
};

export default Loader;
