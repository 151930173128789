import React from "react";
import Layout from "../../components/general/Layout";
import Loader from "../../components/general/Loader";

/**
 * This page is included only for the purpose of being able to interactively edit / style the loader page
 *
 * YOu can visit it at e.g.: https://localhost:8000/hidden/LoaderPage - or whatever the realtive URL you are serving
 */
const LoaderPage = () => {
    return (
        <Layout>
            <Loader />
        </Layout>
    );
};

export default LoaderPage;
